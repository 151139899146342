<template>
  <div class="advertising" v-loading="loading">
    <AdvertisingRow
      v-for="(advertisingRow, inx) in advertisingRowDatas"
      :key="advertisingRow.id"
      :row-data="advertisingRow"
      :is-first="inx === 0"
      :is-last="inx === advertisingRowDatas.length - 1"
      @change="() => init()"
    />
    <div class="advertising-add" @click="() => addDialogRef?.showDialog()">
      <el-icon><Plus /></el-icon> 添加一行
    </div>

    <JuouProForm ref="addDialogRef" :is-dialog="true" title="添加一行" :items="items" @ok="handleAdd"/>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import AdvertisingRow from './AdvertisingRow.vue';
import { JuouProForm, JuouProFormItem, useBaseAsync } from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';

const loading = ref(false);

const props = defineProps<{
  position: string;
}>();

const addDialogRef = ref<InstanceType<typeof JuouProForm>>();

const rowOp: {
    value: any;
    label: string;
}[] = [
  {
    label: '轮播',
    value: 'carousel',
  },
  {
    label: '均匀分布',
    value: 'distribute',
  },
];

const items: JuouProFormItem[] = [
  {
    type: 'select',
    label: '类型',
    name: 'type',
    options: rowOp,
    rule: [{ required: true }],
  },
];

const advertisingRowDatas = ref<any[]>([]);

const init = useBaseAsync(async() => {
  try {
    loading.value = true;
    const res = await useApi().advertisingApi.listAdvertisingRowadvertising({
      conditions: {
        position: props.position,
      },
    });
    if (res?.data?.data) {
      advertisingRowDatas.value = res.data.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

const handleAdd = useBaseAsync(async(data: any) => {
  try {
    await useApi().advertisingApi.addAdvertisingRowadvertising({
      advertisingRowAdminVo: {
        position: props.position,
        type: data.type,
        sequence: advertisingRowDatas.value.length + 1,
      },
    });
    ElMessage.success('添加成功');
    init();
  } catch (error) {
    console.log(error);
  }
});

init();


</script>

<style lang="scss">
.advertising {
  display: flex;
  flex-direction: column;
  gap: 8px;


  &-add {
    cursor: pointer;
    border: dashed 1px #bcbcbc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }
}
</style>
