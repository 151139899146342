<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="租户管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="tsx" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  useBaseUserStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';
import { getCurrentRootUrl } from '@/utils';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const weappTplRes = await useApi().weappTplApi.listWeappTplweappTpl({});
  const weappTplOptions = weappTplRes.data.data?.filter((item: any) => item.templateId !== undefined)?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const wxTplRes = await useApi().wxTplApi.listWxTplwxTpl({});
  const wxTplOptions = wxTplRes.data.data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  columns.value = [
    {
      name: 'name',
      label: '租户名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'logo',
      label: 'Logo',
      rule: [{ required: true }],
      type: 'image',
      action: useApi().uploadImageUrl,
      multiple: false,
      onSuccess(data) {
        console.log(data.data[0]);
        tableRef.value?.tableForm?.setOneFormState('logo', data.data[0]);
      },
    },
    {
      name: 'link',
      label: '租户地址',
      type: 'string',
      render: ({ row }) => (<a href={`/___tenant_${row.id}/login`} target="_blank">{`${getCurrentRootUrl()}/___tenant_${row.id}/login`}</a>),

      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'phone',
      label: '管理员手机号',
      type: 'numberStr',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'password',
      label: '管理员密码',
      type: 'password',
    },
    {
      name: 'weappTplIds',
      label: '授权小程序模板',
      type: 'multiselect',
      options: weappTplOptions,
    },
    {
      name: 'wxTplIds',
      label: '授权H5模板',
      type: 'multiselect',
      options: wxTplOptions,
    },
    {
      name: 'createTime',
      label: '开户时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().tenantApi.pageTenanttenant({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  await useApi().tenantApi.addTenanttenant(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().tenantApi.updateTenanttenant(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().tenantApi.delTenanttenant(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>
