<template>
  <el-dialog v-model="visible" title="配置发布" draggable width="50vw" lock-scroll>
    <el-scrollbar style="height: 60vh;">
      <el-descriptions title="模板信息">
        <el-descriptions-item label="模板名称">{{ tpl?.name || '未知' }}</el-descriptions-item>
        <el-descriptions-item label="模板版本">{{ tpl?.currentVersion || '未知'  }}</el-descriptions-item>
      </el-descriptions>

      <JuouProForm
        v-if="visible && items.length"
        ref="setFormRef"
        :init-form-value="initValues"
        title="配置模板"
        :items="items"
        :hide-cancel="true"
        :hide-ok="true"
        @change="handleFormChange"
      />
    </el-scrollbar>
    <div class="btns">
      <el-button @click="() => visible = false">取消</el-button>
      <el-button type="primary" @click="handlePublish" :loading="loading">发布</el-button>
    </div>
  </el-dialog>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
  useBaseUserStore,
  useBaseTenantStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watch } from 'vue';
import dayjs from 'dayjs';

const emit = defineEmits<{(e: 'close'): void;}>();


const columns = ref<JuouProTableColumn[]>([]);
const setFormRef = ref<InstanceType<typeof JuouProForm>>();

const visible = ref(false);
const weapp = ref({} as any);
const tpl = ref<any>();

const currentDraftId = ref<number>();

const items = ref<JuouProFormItem[]>([]);

const initValues = ref({} as any);

watch(visible, () => {
  if (!visible.value) {
    emit('close');
  }
});

const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'draftId',
      label: '草稿ID',
      type: 'number',
    },
    {
      name: 'currentVersion',
      label: '版本',
      type: 'string',
    },
    {
      name: 'description',
      label: '描述',
      type: 'string',
    },
    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { createTime } }: any) => dayjs(createTime).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const loading = ref(false);

const formData = ref({} as any);

const handleFormChange = useBaseAsync((data: any) => {
  console.log(data);
  formData.value = { ...data };
});


const handlePublish = useBaseAsync(async() => {
  console.log(formData.value);
  try {
    loading.value = true;
    const res = await useApi().weappApi.publishWeappweapp({
      appid: weapp.value.appid,
      ext: formData.value,
    });
    ElMessage.success('提交发布审核成功');
    visible.value = false;
  } finally {
    loading.value = false;
  }
});


init();
defineExpose({
  show: async(_weapp: any, _tpl: any) => {
    let tenant: any;
    try {
      const res = await useApi().tenantApi.getTenantComInfotenant();
      console.log(res);
      if (res?.data?.data) {
        tenant = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }


    weapp.value = _weapp;
    tpl.value = _tpl;
    items.value = _tpl?.extjsFormItems?.map((item: any) => {
      if (_weapp.ext?.[item.name] !== undefined) {
        initValues.value[item.name] = _weapp.ext?.[item.name];
      } else if (item.tenantKey && tenant?.[item.tenantKey]) {
        initValues.value[item.name] = tenant?.[item.tenantKey];
      }
      if (item.required) {
        item.rule = [{ required: true }];
      }
      if (item.type === 'image') {
        return {
          ...item,
          action: useApi().uploadImageUrl,
          multiple: false,
          onSuccess(data: any) {
            console.log(data.data[0]);
            setFormRef.value?.setOneFormState(item.name, data.data[0]);
          },
        };
      }
      return item;
    }) || [];

    console.log(initValues.value);
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
.btns {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: end;
}
</style>
